import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { initializeApp } from 'firebase/app';
import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  UserCredential
} from 'firebase/auth';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  app = initializeApp(environment.firebaseConfig);
  userData: any; // Save logged in user data
  auth = getAuth();
  uid = '';
  userDetailsObs: Observable<any>;
  private _userDetails: Subject<any> = new Subject<any>(); // consider putting the actual type of the data you will receive

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private logger: NGXLogger
  ) {
    this.userDetailsObs = this._userDetails.asObservable();
    /* Saving user data in localstorage when
		logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      this.logger.debug('AuthService.afAuth.authState.subscribe()', user);
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });

    onAuthStateChanged(this.auth, (user) => {
      this.logger.debug('AuthService.onAuthStateChanged()', user);
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        this._userDetails.next(user);
        this.router.navigate(['real-state']).then((value) => {
          this.checkUserIsAuth();
        });
      } else {
        // User is signed out
        this._userDetails.next(null);
        this.router.navigate(['']);
      }
    });
  }

  signInEmailPassword(email: string, password: string) {
    setPersistence(this.auth, browserSessionPersistence)
      .then(async () => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        try {
          const res = await signInWithEmailAndPassword(this.auth, email, password);
          this.logger.debug('AuthService.signInEmailPassword()', res);
          await this.SetUserData(res.user);
          this._userDetails.next(res.user);
          await new Promise((resolve) => setTimeout(resolve, 650));

          this.afAuth.authState.subscribe((user) => {
            this.logger.debug('authState after sign in ', user);
            if (user) {
              this.router.navigate(['real-state']);
            }
          });
        } catch (err) {
          // Handle Errors here.
          const errorMessage = err.message;
          console.error(errorMessage);
          window.alert('[X] Usuario o contraseña inválidos');
        }
      })
      .catch((err) => {
        // Handle Errors here.
        const errorMessage = err.message;
        console.error(errorMessage);
      });
  }

  signInGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
      login_hint: 'user@example.com'
    });

    const auth = getAuth();
    auth.languageCode = 'es';

    return this.afAuth
      .signInWithPopup(provider)
      .then((res) => {
        console.log(res);
        const credentials = res.credential as unknown as UserCredential;
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(credentials);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = res.user;

        console.log(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  checkUserIsAuth() {
    return !!this.auth.currentUser;
  }

  refreshToken() {
    try {
      // @ts-ignore
      getIdToken(this.auth.currentUser!, true)
        .then((idToken) => {
          console.log(idToken);
        })
        .catch((err) => {
          // Error occurred.
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  signUpWEmailPassword(email: string, password: string) {
    createUserWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        const usermail = userCredential.user.email;
        window.alert(`Se creo la cuenta para el usuario ${usermail}`);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(`${errorCode} ${errorMessage}`);
        // ..
      });
  }

  // Sign out
  async SignOut() {
    await this.auth.signOut();
    localStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['home']);
  }

  // Reset Forggot password
  async ForgotPassword(passwordResetEmail: string) {
    try {
      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      window.alert('Password reset email sent, check your inbox.');
    } catch (error) {
      window.alert(error);
    }
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    return userRef.set(userData, {
      merge: true
    });
  }
}
