<app-header *ngIf="isLoggedIn"></app-header>
<main
  class="cds--content content"
  (click)="closeNavBar()"
  style="height: fit-content"
>
  <router-outlet></router-outlet>
  <div>
    <!-- <map-view [latitude]="48.858222" [longitude]="2.2945" [zoom]="8"></map-view> -->
  </div>
</main>
<notifier-container style="color: #fff !important"></notifier-container>
