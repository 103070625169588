import { NgModule } from '@angular/core';

// carbon-components-angular default imports
import {
  AccordionModule,
  ButtonModule,
  CheckboxModule,
  ContainedListModule,
  ContextMenuModule,
  DatePickerModule,
  DialogModule,
  FileUploaderModule,
  GridModule,
  IconModule,
  IconService,
  InlineLoadingModule,
  InputModule,
  ListModule,
  ModalModule,
  ModalService,
  NotificationModule,
  NumberModule,
  OverflowMenuDirective,
  PaginationModule,
  PanelModule,
  PlaceholderModule,
  ProgressIndicatorModule,
  RadioModule,
  SelectModule,
  SkeletonModule,
  SliderModule,
  StructuredListModule,
  TableModule,
  TabsModule,
  TagModule,
  ThemeModule,
  TilesModule,
  ToggleModule,
  TooltipModule,
  UIShellModule
} from 'carbon-components-angular';

import { NgImageSliderModule } from 'ng-image-slider';

// Icons
import CSV20 from '@carbon/icons/es/CSV/20';
import Cube20 from '@carbon/icons/es/cube/20';
import Notification20 from '@carbon/icons/es/notification/20';
import UserAvatar20 from '@carbon/icons/es/user--avatar/20';

// Components
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { PricePipe } from './pipes/price.pipe';

const carbonIcons = [Notification20, UserAvatar20, Cube20, CSV20];

const CarbonModules = [
  NgxPrintModule,
  AccordionModule,
  ButtonModule,
  CheckboxModule,
  ContainedListModule,
  ContextMenuModule,
  DatePickerModule,
  DialogModule,
  FileUploaderModule,
  GridModule,
  IconModule,
  InlineLoadingModule,
  InputModule,
  ListModule,
  ModalModule,
  NotificationModule,
  NumberModule,
  PaginationModule,
  PanelModule,
  PlaceholderModule,
  ProgressIndicatorModule,
  RadioModule,
  SelectModule,
  SkeletonModule,
  SliderModule,
  StructuredListModule,
  TableModule,
  TabsModule,
  TagModule,
  ThemeModule,
  TilesModule,
  ToggleModule,
  TooltipModule,
  UIShellModule
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgImageSliderModule, ...CarbonModules],
  exports: [CommonModule, ReactiveFormsModule, NgImageSliderModule, ...CarbonModules],
  providers: [ModalService, OverflowMenuDirective],
  declarations: [PricePipe, ConfirmationModalComponent]
})
export class CarbonModule {
  constructor(protected iconService: IconService) {
    iconService.registerAll(carbonIcons);
  }
}
