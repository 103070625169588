import { Component, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './shared/service/auth/auth.service';
import { DbService } from './shared/service/db.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  isLoggedIn = false;

  constructor(private dbService: DbService, private authService: AuthService, private logger: NGXLogger) {
    // console.log(this.headerComponent.hasHamburger);
  }

  ngOnInit(): void {
    this.authService.userDetailsObs.subscribe((userDetails) => {
      this.isLoggedIn = this.authService.checkUserIsAuth();
      this.logger.debug('userDetails sub', userDetails);
    });
  }

  closeNavBar() {
    if (this.headerComponent != null) {
      this.headerComponent.hamburgerActive = false;
    }
  }
}
